import useLabels from '../../hooks/useLabels';
import ChatWindowHeader from '../chat-window-header/ChatWindowHeader';
import useInfo from '../../hooks/useInfo';
import ProductivityGainModal from '../productivity-gain-modal/ProductivityGainModal';
import SystemMessage from '../message/SystemEvent';
import Alert from '../../ui/alert/Alert';
import Message from '../message/Message';
import Loader from '../../ui/loader/Loader';
import useLoadChatMessages from '../hooks/useLoadChatMessages';
import useScrollToBottom from '../../hooks/useScrollToBottom';
import useStateRef from '../../hooks/useStateRef';
import useSessionEvents from '../hooks/useSessionEvents';
import { ChatMessageStreamingResponse, MessageDisplayProps, MessageUpdater } from './ChatWindow.types';
import { isoDate } from '../../helpers/dateHelpers';
import { StreamingMessage } from '../message/StreamingMessage';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../contexts/chat/ChatContext';
import { AppRoute } from '../../router/Routing';
import { ChatMessageFeedback } from '../../models/types';
import { ChatProps } from '../../views/chat-view/ChatView';
import { ChatInput, ChatInputModel } from '../chat-input';
import { getClassNames } from '../../helpers/classHelpers';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { TestIds } from '../../mocks/ids';
import { sortWith } from '../../helpers/listsHelpers';
import { BackendEvent, SessionEventTypes } from '../../models/BackendEvents';
import styles from './ChatWindow.module.scss';

const TEST_PERSISTENT_FALLBACK = { getInput: () => {}, removeInput: () => {}, mergeInput: () => {}, getAutoSubmit: () => {}, removeAutoSubmit: () => {}, };

const ChatWindow: React.FC<ChatProps> = ({ chatId, onOpenSidebar, onReloadChats }) => {
    const navigate = useNavigate();
    const { llmOptions, allowedPersonaOptions } = useInfo();
    const { prompt, updateChatMetadata, submitProductivityGain, persistent } = useChat();

    const { getInput, removeInput, mergeInput, getAutoSubmit, removeAutoSubmit } = persistent || TEST_PERSISTENT_FALLBACK;
    const [inputModel, setInputModel, inputModelRef] = useStateRef(getInput(chatId, { message: '' }));

    const chatMessagesRef = useRef<HTMLDivElement>(null);
    const { scrollToBottomWithDelay, scrollToBottomWithBuffer } = useScrollToBottom(chatMessagesRef);

    const onInputModelMerge = useCallback((model: Partial<ChatInputModel>) => { setInputModel(m => ({ ...m, ...model })); mergeInput(chatId, model); }, [chatId, mergeInput, setInputModel]);
    const onInputModelReset = useCallback(() => { setInputModel({}); removeInput(chatId); }, [chatId, removeInput, setInputModel]);

    const { events, documents, setDocuments, setEvents } = useSessionEvents({ scrollToBottomWithDelay });

    const [messages, setMessages, messagesRef] = useStateRef<ChatMessageStreamingResponse[]>([]);
    const [feedbacks, setFeedbacks] = useState<ChatMessageFeedback[]>();

    const { chat, isLoadingChatHistory, chatHistoryError, renderChatHistoryError } = useLoadChatMessages({ chatId });
    const chatInputModel = useMemo(() => {
        const { llm, persona } = chat?.session || {}; // TODO find Temperature prop, might be missing in model

        return {
            ...inputModel,
            documents,
            llm,
            persona: persona === null ? '' : persona, // This check is needed because persona can be null or undefined, in case of undefined it is not loaded yet
        };
    }, [chat?.session, inputModel, documents]);

    useEffect(() => {
        const id = chat?.session.session_id;
        if (id) setInputModel(getInput(id, { message: '' }));

        setEvents(chat?.events ?? []);
        setMessages(chat?.messages ?? []);
        setFeedbacks(chat?.feedback ?? []);
        setDocuments(chat?.session?.documents ?? []); // Initial documents
    }, [chat, setMessages, setFeedbacks, setInputModel, getInput, onInputModelReset, setEvents, setDocuments]);

    useEffect(() => {
        scrollToBottomWithDelay();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }, [chat?.session.session_id, scrollToBottomWithDelay]);

    const updateMessageById = useCallback<MessageUpdater>((id, updates, addIfNotExist) => {
        setMessages((messages) => {
            let targetMessage = messages.find(x => x.id === id);

            if (!targetMessage) {
                if (!addIfNotExist) return messages;
                else {
                    targetMessage = { created: isoDate(undefined, true), ...updates, ...addIfNotExist, id };
                    messages = [...messages, targetMessage];
                }
            }

            const targetIndex = messages.indexOf(targetMessage);
            const updatedMessage = { ...targetMessage, ...updates, id: targetMessage.id };
            const updatedMessages = [...messages];

            updatedMessages.splice(targetIndex, 1, updatedMessage);
            return updatedMessages;
        });
    }, [setMessages]);

    const isStreaming = useMemo(() => messages?.some(x => !!x.isStreaming) ?? false, [messages]);

    const chatTitle = useMemo(() => chat?.session?.subject || '', [chat]);

    const { llm, persona, productivityGain } = useMemo(() => {
        const { llm, persona, productivity_gain: productivityGain } = chat?.session ?? {};
        return { llm, persona, productivityGain };
    }, [chat]);

    const validSelection = useMemo(() => {
        const validPersona = !persona || allowedPersonaOptions.some(p => p.key === persona);
        if (validPersona)
            return true;

        const validModel = !llm || llmOptions.some(x => x.value === llm);
        return validModel;
    }, [llmOptions, llm, persona, allowedPersonaOptions]);

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            chatTitle: labels.newChatTitle,
            placeholder: labels.inputPlaceholder,
            disclaimerNote: labels.disclaimerNote,
        };
    }, [labels]);


    const [showProductivityModal, setShowProductivityModal] = useState(false);
    const onCloseProductivityModal = useCallback(() => setShowProductivityModal(false), []);
    const onOpenProductivityModal = useCallback(() => setShowProductivityModal(true), []);
    const onSubmitProductivityGain = useCallback(async (productivityGain: number) => {
        if (!chatId) return;
        await submitProductivityGain(chatId, productivityGain);
        setShowProductivityModal(false);
        await onReloadChats?.(true);
    }, [chatId, onReloadChats, submitProductivityGain]);


    const onCancelPromptHandler = useCallback(() => {
        const handlers = messagesRef.current?.map(x => x.stream?.abortResponse).filter(x => !!x) as (() => void)[];

        handlers.forEach(handler => handler());
    }, [messagesRef]);

    // Abort when changing unmounting
    useEffect(() => onCancelPromptHandler, [chatId, onCancelPromptHandler]);

    const onRenameChatHandler = useCallback(async (newName: string) => {
        if (!chatId) return;
        const result = await updateChatMetadata(chatId, { subject: newName });
        await onReloadChats?.(true);
        return result;
    }, [chatId, onReloadChats, updateChatMetadata]);

    const onSubmitPromptHandler = useCallback(async (model: ChatInputModel) => {
        if (isLoadingChatHistory || chatHistoryError) return;
        const message = model.message;
        if (!message) return;
        const { response, abort, aiId, humanId } = await prompt(message, chatId);

        updateMessageById(humanId!, {}, { type: 'human', content: message || '' });
        updateMessageById(aiId!, { isStreaming: true, stream: { response, abortResponse: abort } }, { type: 'ai', content: '' });
        scrollToBottomWithDelay();
    }, [chatId, prompt, updateMessageById, scrollToBottomWithDelay, chatHistoryError, isLoadingChatHistory]);

    const onSendPromptHandler = useCallback(async () => {
        await onSubmitPromptHandler(inputModelRef.current);
        onInputModelReset();
    }, [inputModelRef, onSubmitPromptHandler, onInputModelReset]);


    const isAutoSubmitRequiredRef = useRef(getAutoSubmit(chatId));
    const [isAutoSubmitting, setIsAutoSubmitting] = useState(isAutoSubmitRequiredRef.current);
    useEffect(() => {
        if (!isAutoSubmitRequiredRef.current) return;
        if (isLoadingChatHistory) return;
        removeAutoSubmit();
        isAutoSubmitRequiredRef.current = false;
        onSendPromptHandler().finally(() => setIsAutoSubmitting(false));
    }, [onSendPromptHandler, removeAutoSubmit, isLoadingChatHistory]);

    const onNavigateToNewChat = useCallback(() => navigate(AppRoute.chat), [navigate]);
    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);

    const combinedMessages = useMemo(() => ([...messages, ...events]), [messages, events]);
    const sortedCombinedMessages = useMemo(() => sortWith(combinedMessages, (item: any) => item?.timestamp ?? item?.created), [combinedMessages]); // TODO fix any

    //TODO: Group 'groupedEventMessages' with 'groupedAndSortedMessages' to improve code
    const groupedEventMessages = useMemo(() => {
        const messages: any[] = []; // TODO fix any
        let temporaryPairs: any[] = []; // TODO fix any
        let prevEventType = '';

        sortedCombinedMessages.forEach((message) => {
            if ('event_id' in message) {
                if (message.type !== prevEventType) {
                    messages.push(temporaryPairs);
                    temporaryPairs = [];
                    prevEventType = message.type;
                    temporaryPairs.push({ message });
                } else {
                    temporaryPairs.push({ message });
                }
            }

            else {
                if (temporaryPairs.length > 0) {
                    messages.push(temporaryPairs);
                    temporaryPairs = [];
                }

                messages.push(message);
            }
        });

        if (temporaryPairs.length > 0) {
            messages.push(temporaryPairs);
            temporaryPairs = [];
        }

        return messages as (ChatMessageStreamingResponse | BackendEvent)[];
    }, [sortedCombinedMessages]);

    const groupedAndSortedMessages = useMemo(() => {
        return groupedEventMessages.reduce((acc, curr) => {
            if (Array.isArray(curr)) {
                const current = curr;
                const firstElement = current?.[0];
                if (!firstElement) return acc;

                let quantity = current.length;

                // Deleted document event should show the quantity of documents removed
                if (firstElement?.message?.type === SessionEventTypes.documentRemoved) {
                    quantity = current.reduce((acc, curr) => acc + curr?.message?.metadata?.documents?.length, 0);
                }
                const groupedEvent = { ...firstElement.message, quantity };

                return [...acc, groupedEvent];
            }

            return [...acc, curr];
        }, [] as (ChatMessageStreamingResponse | BackendEvent)[]);
    }, [groupedEventMessages]);

    if (!!chatHistoryError) return renderChatHistoryError;

    return <div data-testid={TestIds.chatWindow} className={styles['chat-window']}>
        <div className={styles['chat-window-wrapper']}>
            <ChatWindowHeader
                initialTitle={chatTitle}
                onBurgerClick={onOpenSidebar}
                onClockClick={onOpenProductivityModal}
                onCreateNewChat={onNavigateToNewChat}
                onRenameChat={onRenameChatHandler}
                disableInteractions={isLoadingChatHistory}
            />
            <div className={getClassNames([styles['chat-messages'], isMobile && styles.mobile])} ref={chatMessagesRef}>
                <div className={styles['chat-messages-inner']} >
                    {groupedAndSortedMessages.map((message) => {
                        if ('event_id' in message) return <SystemMessage key={message.event_id} event={message} />;

                        const props: MessageDisplayProps = {
                            chatId,
                            className: styles.message,

                            message: message,
                            feedback: feedbacks?.find((feedback) => feedback.message_id === message.id),
                            llm,
                            persona
                        };

                        return message.stream
                            ? <StreamingMessage key={message.id} {...props} updateMessageById={updateMessageById} scrollToBottom={scrollToBottomWithBuffer} />
                            : <Message key={message.id} {...props} />;
                    })}
                </div>
                {(isLoadingChatHistory || isAutoSubmitting) && <div className={styles['loader-wrapper']}>
                    <Loader isVisible />
                </div>}
            </div>
        </div>

        <div className={styles['input-container']}>
            {validSelection && <ChatInput
                chatId={chatId}
                placeholder={l.placeholder}
                inputNote={l.disclaimerNote}
                model={chatInputModel}
                displayNewChatOptions={false}
                personaOptions={allowedPersonaOptions}
                llmOptions={llmOptions}
                isGenerating={isStreaming}
                isInputDisabled={false}
                isSubmitting={isAutoSubmitting}
                onSubmitPrompt={onSendPromptHandler}
                onCancelPrompt={onCancelPromptHandler}
                onModelMerge={onInputModelMerge}
                focusSignal={`${isStreaming}-${isLoadingChatHistory}`}
            />}
            {!validSelection && <div className={styles['warning']}><Alert type='error' headline={labels.invalidPersona} /></div>}
        </div>

        <ProductivityGainModal initialProductivityGain={productivityGain} chatId={chatId} isOpen={showProductivityModal} onClose={onCloseProductivityModal} onSubmit={onSubmitProductivityGain} />
    </div>;
};

export default ChatWindow;


import useLabels from '../../hooks/useLabels';
import ChatWindowHeader from '../chat-window-header/ChatWindowHeader';
import ChatWelcome from '../chat-welcome/ChatWelcome';
import useInfo from '../../hooks/useInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute, getChatItemRoute } from '../../router/Routing';
import { defaultPersonaKey, defaultTemperature, NEW_PROMPT_PERSISTANCE_KEY, promptOriginNewChatOverrides } from '../../constants/consts';
import { ChatProps } from '../../views/chat-view/ChatView';
import { useChat } from '../../contexts/chat/ChatContext';
import { ChatInput, ChatInputModel } from '../chat-input';
import { validateInputModelSelection } from '../../views/chat-view/usePersistentInputState';
import { TestIds } from '../../mocks/ids';
import { ObjectTypes } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import style from './NewChatWindow.module.scss';


const NewChatWindow: React.FC<ChatProps> = ({ onOpenSidebar, newPromptOrigin, onReloadChats }) => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { getPersonaFromKey } = useInfo();
    const { createChat, persistent } = useChat();
    const { llmOptions, allowedPersonaOptions } = useInfo();

    const promptOrigin = useMemo(() => newPromptOrigin || NEW_PROMPT_PERSISTANCE_KEY, [newPromptOrigin]);

    const defaultLlm = useMemo(() => llmOptions?.[0]?.value, [llmOptions]);
    const { lastInputState, getInput, setInput, mergeInput, setAutoSubmit } = persistent;

    const defaultValues = useMemo(() => ({
        ...getInput(promptOrigin, { message: '', temperature: defaultTemperature, persona: defaultPersonaKey, llm: defaultLlm }),
        ...(promptOriginNewChatOverrides[promptOrigin] ?? {})
    }), [defaultLlm, getInput, promptOrigin]);

    const [inputModel, setInputModel] = useState<ChatInputModel>(defaultValues);

    // React on getInput state changes
    useEffect(() => { setInputModel(s => ({ ...s, ...lastInputState })); }, [defaultValues, lastInputState]);


    const isDocumentUploadEnabled = useMemo(() => getPersonaFromKey(inputModel.persona!)?.documents?.enabled, [inputModel.persona, getPersonaFromKey]);

    // Close side bar on persona without document upload feature
    useEffect(() => {
        if (isDocumentUploadEnabled && hash.includes(ObjectTypes.documents)) return;
        navigate(AppRoute.chat);
    }, [hash, isDocumentUploadEnabled, navigate]);


    const onModelMerge = useCallback((model: Partial<ChatInputModel>) => {
        setInputModel(m => ({ ...m, ...model }));
        mergeInput(promptOrigin, model);
    }, [mergeInput, promptOrigin]);

    useEffect(() => {
        const { isValidSelection, isValidLlmSelection, isValidPersonaSelection } = validateInputModelSelection({ llm: inputModel.llm, persona: inputModel.persona }, llmOptions, allowedPersonaOptions);
        if (isValidSelection) return;

        if (!isValidLlmSelection) onModelMerge({ llm: defaultLlm });
        if (!isValidPersonaSelection) onModelMerge({ llm: defaultPersonaKey });
    }, [inputModel.llm, inputModel.persona, llmOptions, allowedPersonaOptions, defaultLlm, onModelMerge]);

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            chatTitle: labels.newChatTitle,
            placeholder: labels.inputPlaceholder,
            disclaimerNote: labels.disclaimerNote,
        };
    }, [labels]);

    const onSendPromptHandler = useCallback(async () => {
        const { session_id, documents } = await createChat(
            inputModel.message || '',
            inputModel.llm || '',
            inputModel.temperature ?? defaultTemperature,
            inputModel.persona,
            isDocumentUploadEnabled ? inputModel.documents : undefined
        );

        // Remove the persisted input model of the new session and assign the message to the input model of the newly created session
        mergeInput(promptOrigin, { message: '', ...((session_id && isDocumentUploadEnabled) ? { documents: inputModel.documents?.filter(x => !documents?.includes(x)) } : {}) });
        setInput(session_id, { message: inputModel.message, documents: inputModel.documents });
        setAutoSubmit(session_id);

        navigate({ pathname: getChatItemRoute(session_id), hash });
        onReloadChats?.(true);
    }, [inputModel, isDocumentUploadEnabled, promptOrigin, createChat, mergeInput, setInput, setAutoSubmit, navigate, hash, onReloadChats]);

    return (<>
        <div data-testid={TestIds.chatWindow} className={style['chat-window']}>
            <ChatWindowHeader
                initialTitle={l.chatTitle}
                onBurgerClick={onOpenSidebar}
            />

            <div className={style['chat-messages']}>
                <ChatWelcome />
            </div>

            <div className={style['input-container']}>
                <ChatInput
                    chatId={NEW_PROMPT_PERSISTANCE_KEY}

                    model={inputModel}
                    placeholder={l.placeholder}
                    inputNote={l.disclaimerNote}

                    personaOptions={allowedPersonaOptions}
                    llmOptions={llmOptions}
                    onModelMerge={onModelMerge}
                    onSubmitPrompt={onSendPromptHandler}

                    displayNewChatOptions={true}
                />
            </div>
        </div>
    </>);
};

export default NewChatWindow;
import React, { type PropsWithChildren, } from 'react';
import MsalAuthContextProvider from '../auth/MsalAuthContextProvider';
import ChatServiceProvider from '../chat/ChatServiceProvider';
import useAnalytics from '../../hooks/useAnalytics';
import AppInfoServiceProvider from '../app-info/AppInfoServiceProvider';
import BackendEventsServiceProvider from '../backend-events/BackendEventsServiceProvider';

const AppContextBootstrap: React.FC<PropsWithChildren> = ({ children }) => {

    useAnalytics();

    return (
        <MsalAuthContextProvider>
            <BackendEventsServiceProvider>
                <AppInfoServiceProvider>
                    <ChatServiceProvider>
                        {children}
                    </ChatServiceProvider>
                </AppInfoServiceProvider>
            </BackendEventsServiceProvider>
        </MsalAuthContextProvider>
    );
};

export default AppContextBootstrap;
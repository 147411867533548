export default class TaskCompletionSource<T = unknown> {
    constructor() {
        let _success = (_: T) => {};
        let _failure = (_: any) => {};

        this.success = (result: T) => _success(result);
        this.failure = (reason: any) => _failure(reason);

        this.promise = new Promise<T>((s, f) => {
            _success = s;
            _failure = f;
        });
    }
    readonly success: (result: T) => void;
    readonly failure: (reason: any) => void;
    readonly promise: Promise<T>;
}

import FileInput from "../../../../../file-input/FileInput";
import labels from "../../../../../../data/labels";
import DocumentList from "./components/document-list/DocumentList";
import FileInputProgress from '../../../../../file-input-progress/FileInputProgress';
import Loader from "../../../../../loader/Loader";
import Information from './components/information/Information';
import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { DocumentInfoModel, DocumentRow } from "../../../../../../models/ChatTypes";
import { TestIds } from "../../../../../../mocks/ids";
import { DocumentUploadStatus } from '../../../../../../helpers/statusHelpers';
import { NotificationType, useErrorHandlerContext } from '../../../../../../contexts/error-handler/ErrorContext';
import { getExtension, isFileSizeValid, readableFileSize } from '../../../../../../helpers/fileHelpers';
import { ErrorHandler } from '../../../../../../contexts/error-handler/ErrorHandler';
import { NEW_PROMPT_PERSISTANCE_KEY } from '../../../../../../constants/consts';
import { AxiosProgressEvent } from 'axios';
import { useAppInfo } from '../../../../../../contexts/app-info/AppInfoContext';
import styles from "./Document.module.scss";


type ExtendedNotificationType = NotificationType & { fileName?: string; fileSize?: string; };
export const Documents = ({ chatId }: { chatId: string; }) => {
    const { documents } = useAppInfo();
    const { persistent: { getInput, mergeInput }, uploadDocument, fetchDocuments } = useChat();
    const initialLoadRef = useRef(false);
    const maxDocsUploadCountRef = useRef(NaN);
    const tempProgressesRef = useRef<Record<string, AxiosProgressEvent>>();
    const tempFileUploadRef = useRef<Record<string, File>>({});

    const [data, setData] = useState<DocumentRow[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progresses, setProgresses] = useState<Record<string, AxiosProgressEvent>>();

    const persistentInput = useCallback(() => getInput(NEW_PROMPT_PERSISTANCE_KEY, { documents: [] }), [getInput]);
    const setDocumentIds = useCallback((documents: string[]) => mergeInput(NEW_PROMPT_PERSISTANCE_KEY, { documents: [...(persistentInput().documents ?? []), ...documents] }), [mergeInput, persistentInput]);
    const removeDocumentIds = useCallback((documents: string[]) => mergeInput(NEW_PROMPT_PERSISTANCE_KEY, { documents: (persistentInput().documents ?? []).filter(doc => !documents.includes(doc)) }), [mergeInput, persistentInput]);
    const { errorId, registerError, removeError } = useErrorHandlerContext();

    const incrementMaxUploadCount = useCallback(() => maxDocsUploadCountRef.current = maxDocsUploadCountRef.current + 1, []);
    const subtractMaxUploadCount = useCallback(() => maxDocsUploadCountRef.current = maxDocsUploadCountRef.current - 1, []);

    // Needed for multiple error possibility 
    const [errors, setErrors] = useState<ExtendedNotificationType[]>([]);
    const handleSetMultiError = useCallback((e: ExtendedNotificationType) => setErrors(prev => [...prev, e]), []);

    const resetErrors = useCallback(() => {
        removeError(errorId);
        setErrors([]);
    }, [errorId, removeError]);


    const loadDocuments = useCallback(async (documentIds?: string[]) => {
        resetErrors();
        setIsLoading(true);

        try {
            const response = await fetchDocuments(documentIds ?? persistentInput().documents ?? [], chatId);

            if (response.errors && Object.keys(response.errors).length > 0) {
                const errorMessages = Object.entries(response.errors).map(([_, errorMessage]) => `${errorMessage}`).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: errorMessages } });
            }

            if (response.documents) {
                const rows = Object.values(response.documents).map((doc) => {
                    return ({
                        documentId: doc.id,
                        fileName: doc.display.name || '',
                        description: doc.display.description || '',
                        status: doc.state?.status || DocumentUploadStatus.Ready,
                        size: doc.size,
                        extension: doc.format,
                        created: doc.created
                    });
                });
                maxDocsUploadCountRef.current = rows.length;
                setData(rows);
            }

            initialLoadRef.current = true;
        }
        catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: labels.fetchErrorMessage, details: error } });
        }
        finally {
            setIsLoading(false);
        }
    }, [resetErrors, fetchDocuments, persistentInput, chatId, registerError, errorId]);

    // Init documents load
    useEffect(() => { !initialLoadRef.current && loadDocuments(); }, [loadDocuments]);

    const handleFileUpload = useCallback(async (file: File) => {
        // Check if the file size is valid
        const { name: fileName } = getExtension(file?.name) || { extension: 'unknown', name: 'undefined' };
        const maxFileSize = documents?.length ?? 20e6;
        const readableMaxFileSize = readableFileSize(maxFileSize);
        const fileSize = readableFileSize(file?.size ?? 0);
        const isDuplicate = !!tempFileUploadRef.current?.[file?.name] || !!tempFileUploadRef.current?.[fileName] || data.some(row => row.fileName === fileName);

        // exceededFileUploadLimit
        if (documents?.max && maxDocsUploadCountRef.current >= documents.max) {
            handleSetMultiError({ type: 'notification', headline: labels.uploadLimitErrorHeadline, description: labels.uploadLimitErrorMessage(documents?.max) });
            return { break: true };
        }

        if (!isFileSizeValid(file, maxFileSize)) {
            handleSetMultiError({ type: 'notification', headline: labels.uploadErrorHeadline, description: labels.exceededDocSizeError(readableMaxFileSize), fileName: file.name, fileSize });
            return;
        }

        if (isDuplicate) {
            const duplicateErrorMessage = labels.duplicateDocumentError;
            handleSetMultiError({ type: 'notification', headline: labels.duplicateNameErrorHeadline, description: duplicateErrorMessage, fileName: file.name });
            return;
        };
        // Set temp files 
        tempFileUploadRef.current[fileName] = file;

        // Assume that the file is valid and increment the count
        incrementMaxUploadCount();

        try {
            const props: DocumentInfoModel = await uploadDocument(file, chatId, p => {
                const prev = tempProgressesRef.current ?? {};
                const events = { ...prev, [fileName]: p };
                tempProgressesRef.current = events;
                setProgresses(events);
            });

            const { id, size, format, state } = props;
            if (!id) return;

            // Handle the case of new chat where the session ID is not yet available
            if (!chatId) setDocumentIds([id]);
            const uploadStatus = state?.status || DocumentUploadStatus.Processing; // TODO: Why wouldn't it have a status?

            setData(prev => [...prev, { ...props, fileName, description: '', documentId: id, size: size, extension: format, status: uploadStatus }]);
        }
        catch (error: any) {
            handleSetMultiError({ type: 'notification', headline: labels.uploadErrorHeadline, description: labels.uploadErrorMessage, details: error, fileName: file.name });
            // Decrement the count if the upload fails
            subtractMaxUploadCount();
        }
    }, [data, documents, incrementMaxUploadCount, handleSetMultiError, uploadDocument, chatId, setDocumentIds, subtractMaxUploadCount]);

    const handleFilesUpload = useCallback(async (files: FileList) => {
        if (!files.length) return;
        resetErrors();

        for (const file of Object.values(files)) {
            const response = await handleFileUpload(file);
            if (response?.break) {
                break;
            }
        }
    }, [handleFileUpload, resetErrors]);


    const handleInvalidFiles = useCallback(() => {
        handleSetMultiError({ type: 'notification', headline: labels.invalidDocumentTypeHeader, description: labels.invalidDocumentTypeMessage(documents?.formats) });
    }, [documents?.formats, handleSetMultiError]);

    const progress = useMemo(() => {
        const values = Object.values(progresses ?? []);
        const total = values.reduce((acc, curr) => acc + (curr?.total ?? 0), 0);
        const loaded = values.reduce((acc, curr) => acc + (curr?.loaded ?? 0), 0);
        if (!total || !loaded) return undefined;
        return { total, loaded };
    }, [progresses]);

    const groupErrorsByHeadline = useCallback((errors: ExtendedNotificationType[]) => {
        const mergedNotifications = errors.reduce((prev, curr, index) => {
            const matchingErrorType = prev.find(p => p.headline === curr.headline);
            const suffix = (curr.fileName || curr.fileSize) ? ' ' + [curr.fileName, curr.fileSize].filter(Boolean).join(' ') + ',' : '';

            return !matchingErrorType
                ? [...prev, { ...curr, description: curr.description + suffix }]
                : [...prev.filter(x => x.headline !== matchingErrorType.headline), { ...matchingErrorType, description: `${matchingErrorType?.description}${suffix}` }];
        }, [] as NotificationType[]);

        if (!mergedNotifications.length) return [];
        return mergedNotifications;
    }, []);


    // Set error once loading is done
    useEffect(() => {
        if (((progress?.loaded === progress?.total && errors.length) || (progresses === undefined && errors.length))) {
            const notifications = groupErrorsByHeadline(errors);
            registerError({ [errorId]: { type: 'notifications', notifications } });
        }
    }, [errorId, errors, groupErrorsByHeadline, progress, progresses, registerError, removeError, resetErrors]);

    return (
        <div className={styles.wrapper} data-testid={TestIds.documentView}>
            <div className={styles['file-drop-area']}>
                <FileInput
                    multiple
                    onFilesSelected={handleFilesUpload}
                    onInvalidFiles={handleInvalidFiles}
                    accept={documents?.formats}
                />
                <FileInputProgress
                    hasError={!!errors.length}
                    className={styles['file-drop-progress']}
                    progress={progress}
                    onDoneLoading={() => {
                        // Reset temporary progress & file upload state
                        tempProgressesRef.current = undefined;
                        tempFileUploadRef.current = {};
                    }}
                />
            </div>

            <ErrorHandler.Notifications id={errorId} />
            {isLoading
                ? <Loader />
                : <>
                    <DocumentList
                        chatId={chatId}
                        documents={data}
                        removePersistentDocsByIds={removeDocumentIds}
                        setIsLoading={setIsLoading}
                        onRefresh={loadDocuments}
                        setData={setData}
                        onDelete={subtractMaxUploadCount}

                        errorId={errorId}
                        registerError={registerError}
                        removeError={removeError}
                    />
                    <Information />
                </>
            }
        </div>
    );
};

import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useLabels, { useInputValidationLabels } from '../../hooks/useLabels';
import Textarea from '../../ui/textarea/Textarea';
import LoadingIcon from '../../ui/loading-icon/LoadingIcon';
import useTextareaMutations from '../../hooks/useTextareaMutations';
import useInputFocus from '../hooks/useInputFocus';
import useInfo from '../../hooks/useInfo';
import { Icon } from '../../ui/icon/Icon';
import { IconStyles, IconTheme } from '../../ui/icon/Icon.types';
import { getClassNames } from '../../helpers/classHelpers';
import { isEventEnter } from '../../helpers/eventHelpers';
import { maxMessageLength } from '../../constants/consts';
import { ChatInputModel, ChatInputPropsBase } from './models';
import { isLiteralObject, isString } from '../../helpers/typeHelpers';
import { ChatInputOptions } from './ChatInputOptions';
import { useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { TestIds } from '../../mocks/ids';
import { Icons } from '../../ui/icon/icons/material';
import { ObjectTypes, usePathRouting } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { useLayout } from '../../contexts/layout/LayoutContext';
import { DocumentUploadStatus } from '../../helpers/statusHelpers';
import { useUploadStatus } from '../../ui/markdown-wrapper/hooks/useActions/actions/documents/components/document-list/DocumentList';
import style from './ChatInput.module.scss';


export type ChatInputProps = ChatInputPropsBase & {
    chatId: string;
    model: ChatInputModel;

    /**Should return true if the model is valid, false if invalid without error or a string if invalid with a validation error. The ChatInput validates the length of the message, other props should be validated by the caller */
    validateModel?: (model: ChatInputModel) => boolean | string;

    className?: string;

    placeholder: string;
    inputNote?: string;

    /**Set to true to display the prompt options (llm, persona, etc..)*/
    displayNewChatOptions?: boolean;

    /**Indicates that the provided onSubmitPrompt handler is active. Only provide this value to keep the submitting state active beyond the lifespan of the promise returned from onSubmitPrompt  */
    isSubmitting?: boolean;
    onSubmitPrompt: () => Promise<any>;

    /**When true, the input field will be enabled and it will display the cancel prompt icon */
    isGenerating?: boolean;
    onCancelPrompt?: () => void;

    /**Whenever this value changes, the input will set to focus to itself */
    focusSignal?: boolean | number | string;
};

const getIconTheme = (uploadStatus: DocumentUploadStatus | undefined, documentIdsCount: number, isSidebarVisible: boolean) => {
    if (documentIdsCount <= 0) return isSidebarVisible ? IconTheme.info : IconTheme.inherit;

    let iconTheme = IconTheme.inherit;
    if (uploadStatus === DocumentUploadStatus.Error) return IconTheme.error;
    else if (uploadStatus === DocumentUploadStatus.Ready) return IconTheme.success;
    return iconTheme;
};

const ChatInput: React.FC<ChatInputProps> = ({ chatId, model, onModelMerge, validateModel: externalValidation, className, placeholder, inputNote, isInputDisabled, isSubmitting: isSubmittingExternal, isGenerating, llmOptions, personaOptions, displayNewChatOptions, onSubmitPrompt, onCancelPrompt, focusSignal }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { llm, message, persona, temperature, documents } = model || {};
    const resizeSignal = !message;

    const { getPersonaFromKey } = useInfo();
    const { uploadStatus } = useUploadStatus(chatId);

    const isDocumentUploadEnabled = useMemo(() => getPersonaFromKey(persona!)?.documents?.enabled, [getPersonaFromKey, persona]);

    const { errorId, registerError, removeError } = useErrorHandlerContext();
    useTextareaMutations(textareaRef, [resizeSignal]);
    useInputFocus(textareaRef, [focusSignal, llm, persona, temperature]);

    const labels = useLabels();
    const inputValidationLabels = useInputValidationLabels();
    const { isRightSidebarVisible } = useLayout();

    const [isSubmittingInternal, setIsSubmittingInternal] = useState(false);

    const characterLimit = useMemo(() => {
        let limit: number | undefined = undefined;
        if (persona) limit = personaOptions.find(x => x.key === persona)?.characterLimit;
        if (!limit && llm) limit = llmOptions.find(x => x.value === llm)?.characterLimit;

        return limit ?? maxMessageLength;
    }, [llmOptions, personaOptions, llm, persona]);

    /**Returns true if valid, false if empty, error message if invalid with a validation error */
    const internalValidation = useCallback((m: ChatInputModel) => {
        if (!m?.message?.trim()) return false;

        const limitReached = m.message.length > characterLimit;
        if (!limitReached) return true;

        return `${inputValidationLabels.messageTooLong} ${m.message.length}/${characterLimit}`;
    }, [characterLimit, inputValidationLabels]);

    /**true if the model is valid, false if invalid without error or a string if invalid with a validation error */
    const modelValidation = useMemo(() => {
        const internal = internalValidation(model);
        if (internal !== true)
            return internal;
        return (externalValidation?.(model) ?? true);
    }, [internalValidation, externalValidation, model]);


    const isSubmitting = useMemo(() => isSubmittingInternal || isSubmittingExternal, [isSubmittingInternal, isSubmittingExternal]);
    const isDisabled = useMemo(() => isInputDisabled || isSubmitting, [isInputDisabled, isSubmitting]);
    const isInvalid = useMemo(() => modelValidation !== true, [modelValidation]);
    const preventSend = useMemo(() => isDisabled || isInvalid, [isDisabled, isInvalid]);

    const { invokePathNavigateToSidePanel } = usePathRouting();

    const onSendClickHandler = useCallback(async () => {
        removeError(errorId);

        if (isGenerating && onCancelPrompt) {
            return onCancelPrompt();
        }
        if (preventSend) return;

        try {
            setIsSubmittingInternal(true);
            await onSubmitPrompt();
        }
        catch (e) {
            let errorHeadline = "";
            let errorDescription: Record<string, string> | undefined = undefined;
            if (isString(e)) errorHeadline = e;
            else if (e instanceof Error) {
                errorHeadline = e.message;
            }
            else if (isLiteralObject(e)) {
                //TODO: Better error extraction is needed here. This assume that the error is an Axios/http error
                let { statusText = "Unknown", status = -1, data, detail } = e as any;
                switch (status) {
                    case 0: {
                        errorHeadline = labels.offlineError;
                        break;
                    }
                    default: {
                        errorHeadline = `(${status}): ${statusText}`;
                        errorDescription = data?.detail ?? detail ?? data;
                        // errorDescription = detail ? <pre>{JSON.stringify(detail, undefined, 4)}</pre> : undefined;
                        break;
                    }
                }
            }

            registerError({ [errorId]: { type: 'modal', headline: errorHeadline, notification: { headline: labels.submitFailed, details: errorDescription } } });
        }
        finally {
            setIsSubmittingInternal(false);
        }

    }, [onSubmitPrompt, preventSend, labels, isGenerating, onCancelPrompt, registerError, errorId, removeError]);

    const onDocumentUploadClickHandler = useCallback(async () => {
        invokePathNavigateToSidePanel('/' + ObjectTypes.documents, true);
    }, [invokePathNavigateToSidePanel]);

    const isComposingRef = useRef(false);
    useLayoutEffect(() => {
        const $elm = textareaRef.current;
        if (!$elm) return;

        const handlers: [keyof HTMLElementEventMap, () => void][] = [
            ['compositionstart', () => isComposingRef.current = true],
            ['compositionend', () => isComposingRef.current = false]
        ];

        handlers.forEach(([e, h]) => $elm.addEventListener(e, h));
        return () => handlers.forEach(([e, h]) => $elm.removeEventListener(e, h));
    }, [textareaRef]);

    const onKeyDownHandler = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (isComposingRef.current) return;

        const isEnter = isEventEnter(e);
        if (!isEnter) return;

        const holdsShift = e.shiftKey;
        if (holdsShift) return;

        e.preventDefault();
        onSendClickHandler();
    }, [onSendClickHandler]);


    const onChangeHandler = useCallback((s: string) => {
        if (s && !s.trim()) return;
        onModelMerge({ message: s });
    }, [onModelMerge]);

    const renderCta = useMemo(() => {
        const render = (icon: React.ReactNode, onClick: () => void, className?: string | false) => (
            <div onClick={onClick} className={getClassNames([className, style['cta-icon']])} >{icon}</div>
        );

        if (isSubmitting) return render(<LoadingIcon className={'df-icon'} />, () => {});

        if (isGenerating) return <Icon.Base
            title={labels.stopSubmitTitle}
            iconName={Icons.stop}
            iconStyle={IconStyles.filled}
            theme={IconTheme.accent}
            onClick={onCancelPrompt}
        />;

        return <Icon.Base
            title={labels.submitTitle}
            testId={TestIds.sendButton}
            iconName={Icons.arrowUpward}
            isDisabled={preventSend || isInvalid}
            onClick={onSendClickHandler}
        />;
    }, [labels, preventSend, isInvalid, isSubmitting, isGenerating, onCancelPrompt, onSendClickHandler]);

    const renderCuf = useMemo(() => {
        if (!isDocumentUploadEnabled) return;

        const documentLength = documents?.length || 0;
        const theme = getIconTheme(uploadStatus, documentLength, isRightSidebarVisible);

        return (
            <div className={style['upload-icon-wrapper']}>
                <Icon.Base
                    title={labels.uploadDocumentTitle}
                    testId={TestIds.uploadDocButton}
                    iconName={Icons.uploadDocument}
                    dataAttributes={{ 'data-documents-quantity': documentLength > 9 ? '9+' : documentLength.toString() ?? '' }}
                    theme={theme}
                    className={getClassNames([style['cta-icon']])}
                    onClick={onDocumentUploadClickHandler}
                />
            </div>
        );
    }, [documents?.length, isDocumentUploadEnabled, isRightSidebarVisible, labels.uploadDocumentTitle, onDocumentUploadClickHandler, uploadStatus]);


    return (
        <div data-testid={TestIds.chatInputContainer} className={getClassNames([className, style['chat-input']])}>
            {displayNewChatOptions && <ChatInputOptions llm={llm} temperature={temperature} persona={persona} onModelMerge={onModelMerge} llmOptions={llmOptions} personaOptions={personaOptions} isInputDisabled={isDisabled} />}

            <div className={getClassNames([style.container, 'df-input-container df-addon-end'])}>
                <Textarea
                    testId={TestIds.chatInput}
                    ref={textareaRef}
                    className={style.textarea}
                    value={message || ''}
                    placeholder={placeholder}
                    isInvalid={isString(modelValidation)}
                    isDisabled={isDisabled}
                    withIcon
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHandler}
                    maxLength={characterLimit + 100} // +100 - Needed for allowing users to exceed the limit so the error message can be displayed
                />
                <div className={getClassNames([style['cta-icon-wrapper'], 'df-addon-content'])}>
                    {renderCuf}
                    {renderCta}
                </div>

            </div>
            {isString(modelValidation) && <label className='df-helper-text df-color-error'>{modelValidation}</label>}
            {inputNote && <label className={getClassNames([style['disclaimer-note'], 'df-helper-text'])}>{inputNote}</label>}
            <ErrorHandler.Modal id={errorId} />
        </div>
    );
};

export default ChatInput;
import { useMemo, useState } from 'react';
import Button, { ButtonThemes } from '../button/Button';
import styles from './Description.module.scss';

const Description = ({ threshold = 140, description, labels: { seeMore, seeLess } }: { labels: { seeMore: string, seeLess: string; }, threshold?: number, description: string; }) => {
    const className = styles.description;
    const [showAll, setShowAll] = useState(false);

    const expandCta = useMemo(() => {
        if (description.length < threshold) return null;
        return <>&nbsp;<Button className={styles['toggle-button']} label={showAll ? seeLess : seeMore} theme={ButtonThemes.textPrimary} onClick={() => setShowAll(s => !s)} /></>;
    }, [description.length, seeLess, seeMore, showAll, threshold]);

    if (description.length < threshold || showAll) {
        return <div className={className}>
            {description}
            {expandCta}
        </div>;
    }

    return <div className={className}>
        {description.slice(0, 100).trim() + ' ...'}
        {expandCta}
    </div>;
};

export default Description;
import PageLayout from '../../layouts/page-layout/PageLayout';
import Information from '../../elements/information/Information';
import style from './InformationView.module.scss';

const InformationView: React.FC = () => {
    return (
        <PageLayout mainClassName={style['information-view']} notification={{
            id: 'chatbot-interface-change',
            label: 'The Danfoss AI Chatbot interface is changing. Changes are expected before the end of November. To learn more',
            urlLabel: 'click here',
            url: 'https://danfoss.sharepoint.com/sites/AI/SitePages/Danfoss-AI-Chatbot.aspx',
            persist: 'session'
        }}>
            <div className={style['content-wrapper']}>
                <Information />
            </div>
        </PageLayout>
    );
};

export default InformationView;

import useLabels from './useLabels';
import { useCallback, useMemo, } from 'react';
import { rawLlmToUI } from '../helpers/transformHelpers';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import { sortItemsBy } from '../helpers/listsHelpers';

export default function useInfo() {
    const labels = useLabels();
    const { llms, personas, access, documents } = useAppInfo();

    const llmOptions = useMemo(() => {
        if (!llms) return [];
        return llms
            .map(rawLlmToUI)
            .sort((a, b) => a.sort - b.sort);
    }, [llms]);

    const personaOptions = useMemo(() => {
        if (!personas?.length) return [];
        let items = [...sortItemsBy(personas, "sort"), {
            key: "", // This is advanced persona (it doesn't have a key) // TODO: Consider making an enum key?
            title: labels.advancedTitle,
            description: labels.advancedDescription,
            documents: {
                enabled: true,
            },
        }].map((persona) => {
            return {
                ...persona,
                documents: {
                    ...persona.documents,
                    ...documents,
                    enabled: documents?.enabled || persona.documents?.enabled,
                },
            };
        });

        return items;
    }, [documents, labels.advancedDescription, labels.advancedTitle, personas]);

    const allowedPersonaOptions = useMemo(() => {
        return personaOptions.filter(p => !p.key || !!access?.personas?.[p.key]);
    }, [personaOptions, access]);

    const getPersonaFromKey = useCallback((key: string) => personaOptions?.find(p => p.key === key), [personaOptions]);

    const getLlmFromKey = useCallback((key: string) => llms?.find(llm => llm.key === key), [llms]);

    const getLlmLabelFromKey = useCallback((key: string) => getLlmFromKey(key)?.title || key, [getLlmFromKey]);

    const getPersonaLabelFromKey = useCallback((key: string) => getPersonaFromKey(key)?.title ?? key, [getPersonaFromKey]);

    return {
        llmOptions,
        personaOptions,
        allowedPersonaOptions,
        getPersonaFromKey,
        getLlmFromKey,
        getLlmLabelFromKey,
        getPersonaLabelFromKey
    };
}
import { AccountInfo, EventType, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { Configuration, PublicClientApplication, IPublicClientApplication } from "@azure/msal-browser";
import envConfig from "../helpers/envConfigHelper";
import axios from 'axios';
import { createImageFromInitials } from '../helpers/avatarHelpers';
import TaskCompletionSource from '../helpers/TaskCompletionSource';

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"],
    // domainHint: "danfoss.com"
};


export const loginUser = (instance: IPublicClientApplication) => {
    instance.loginRedirect(loginRequest)
        .catch(console.error);
};

export const logoutUser = (instance: IPublicClientApplication, account?: AccountInfo) => {
    if (!account) return;
    instance.logoutRedirect({ account })
        .catch(console.error);
};

const msalConfig = {
    auth: {
        clientId: envConfig.get("msal-client-id"),
        authority: `https://login.microsoftonline.com/${envConfig.get("msal-tenant-id")}`,
        redirectUri: envConfig.get("msal-redirect-uri", window.location.origin),
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
} as Configuration;



export const msalInstance = new PublicClientApplication(msalConfig);

let msalInitPromise = new TaskCompletionSource();
// Useful for debugging
msalInstance.addEventCallback(e => {
    switch (e.eventType) {
        case EventType.HANDLE_REDIRECT_START:
        case EventType.SSO_SILENT_START:
            msalInitPromise = new TaskCompletionSource();
            break;
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
            msalInitPromise.success(null);
            break;
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
            msalInitPromise.promise.catch(() => {});
            msalInitPromise.failure('Login failed');
            break;
        default: break;
    }
});

const apiScope = `${envConfig.get("api-app-id-uri")}/.default`;

export const getApiAccessToken = (account?: AccountInfo) => getAccessTokenForScopes([apiScope], account);
export const getDefaultAccessToken = (account?: AccountInfo) => getAccessTokenForScopes(loginRequest.scopes, account);

const getAccessTokenForScopes = async (scopes: string[], account?: AccountInfo) => {
    if (!isLoggedIn()) return undefined;
    try {
        const request: SilentRequest = {
            scopes: scopes,
            account: account || getAccount()
        };
        await msalInitPromise.promise;
        const response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error: any) {
        throw error;
    }
};

export const getPhoto = async (user: (AccountInfo & { avatar?: string; }) | undefined, getAccessToken: (account?: AccountInfo) => Promise<string | undefined>) => {
    if (!user || !getAccessToken) return undefined;

    try {
        const accessToken = await getAccessToken(user);
        const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photos/120x120/$value";

        const response = await axios(graphEndpoint, {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: 'blob'
        });
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(response.data);

        return blobUrl;
    }
    catch {
        if (user.avatar) return user.avatar;
        else return await createImageFromInitials(120, user.name || user.username);
    }
};

export const getAccount = () => {
    return msalInstance.getAllAccounts()[0];
};

export const isLoggedIn = () => {
    return !!getAccount();
};
import React, { useCallback, useEffect, useMemo, useState, type PropsWithChildren, } from 'react';
import Header from '../../ui/header/Header';
import useLabels from '../../hooks/useLabels';
import useStorage from '../../hooks/useStorage';
import useUserInstructionsModal from '../../hooks/useUserInstructionsModal';
import { Icons } from '../../ui/icon/icons/material';
import { Icon } from '../../ui/icon/Icon';
import { getClassNames } from '../../helpers/classHelpers';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { BaseUIType } from '../../models/UITypes';
import style from './PageLayout.module.scss';

type Props = BaseUIType & {
    notification?: {
        id: string;
        label: string;
        urlLabel?: string;
        url?: string;
        persist?: 'session' | 'local';
    };
    mainClassName?: string;
};

const PageLayout: React.FC<PropsWithChildren<Props>> = ({ notification, testId, mainClassName, children }) => {
    // Disabled disclaimer since terms api is making the site crash - to be enabled again
    const labels = useLabels();
    const navigate = useNavigate();
    const { openModal, renderModal } = useUserInstructionsModal();

    const userDropdownItems = useMemo(() => {
        return [
            { label: labels.informationPageCta, onClick: () => navigate(AppRoute.info), },
            { label: labels.userInstructions, onClick: openModal, }
        ];
    }, [labels, openModal, navigate]);
    return (<>
        <div className={getClassNames([style['page-layout'], ''])} data-testid={testId}>
            <div>
                <Header userDropdownItems={userDropdownItems} />
            </div>

            {notification && <Notification {...notification} />}

            <main className={getClassNames([mainClassName, style['main-section']])}>
                {children}
            </main>
        </div>

        {renderModal}
    </>);
};

export default PageLayout;


const Notification = (props: Props['notification']) => {
    const { id, label, urlLabel, url, persist } = props || {};

    const [isVisible, setIsVisible] = useState(false);
    const { get, set } = useStorage(`notification`, persist);

    useEffect(() => {
        if (!id) return;
        const isVisible = get(id)?.visible;
        if (!persist || isVisible === undefined) setIsVisible(true);
        else setIsVisible(isVisible);
    }, [get, id, persist, props]);

    const handleClose = useCallback(() => {
        if (!id || !persist) return;
        setIsVisible(false);
        set(id, { visible: false });
    }, [id, persist, set]);

    if (!props || !isVisible) return null;

    return <div className={style['notification-alert']} >
        <div className={style['content']}>
            <div className={style['text-container']}>
                <b>{label}</b>&nbsp;&nbsp;
                {url && <a href={url}>{urlLabel}</a>}
            </div>

            {persist && <Icon.Base title={''} iconName={Icons.clear} onClick={handleClose} />}
        </div>
    </div>;
};
import React, { type ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { DfModal } from '@danfoss/mosaic-react';
import { type ModalSizeType } from '@danfoss/mosaic-core';
import { getClassNames } from '../../helpers/classHelpers';
import { portalId } from '../../constants/consts';
import { BaseUIType } from '../../models/UITypes';
import style from './Modal.module.scss';

export type ModalProps = BaseUIType & {
    isOpen?: boolean;
    headline?: string;
    header?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;
    hideCloseIcon?: boolean;
    closeOnBackdropClick?: boolean;
    closeOnEscape?: boolean;
    size?: ModalSizeType;
    onClose?: () => void;

    className?: string;
};

const Modal: React.FC<ModalProps> = ({ testId, isOpen, headline, header, body, footer, hideCloseIcon, closeOnBackdropClick, closeOnEscape, size, onClose, className }) => {
    const outlet: HTMLElement | null = (typeof window !== "undefined") ? document.getElementById(portalId) : null;

    const classNames = useMemo(() => getClassNames([className, style.modal, !body && style['hide-body'], !footer && style['hide-footer']]), [body, footer, className]);

    const modal = (
        <DfModal
            role='dialog'
            className={classNames}
            isOpen={isOpen}
            heading={headline}
            onBackdropClick={onClose}
            onModalClose={onClose}
            hideCloseIcon={hideCloseIcon}
            closeOnBackdropClick={closeOnBackdropClick}
            closeOnEscape={closeOnEscape}
            size={size}
            data-testid={testId}
        >
            <div slot='modal-header'>{header}</div>
            <div slot='modal-body'>{body}</div>
            {!!footer && <div slot='modal-footer'>{footer}</div>}
        </DfModal>
    );

    if (!outlet) return modal;
    return createPortal(modal, outlet);
};

export default Modal;
import useEffectAsync from '../../hooks/useEffectAsync';
import { Dispatch, SetStateAction } from 'react';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../../contexts/chat/ChatContext';

type Props = {
    setChats: Dispatch<SetStateAction<SessionResponse[] | undefined>>;
};

const useLoadAllChats = ({ setChats }: Props) => {
    const { getAllChats } = useChat();

    useEffectAsync(token => () => {
        (async () => {
            try {
                if (token.cancelled) return;
                const chats = await getAllChats();
                setChats(chats);
            } catch (e) {
                if (token.cancelled) return;
                console.error({ e });
            }
        })();
    }, [getAllChats, setChats]);
};

export default useLoadAllChats;
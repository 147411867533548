import React, { type PropsWithChildren, createContext, useContext, useState, useEffect, useRef } from 'react';
import type { InfoResponse, AccessInfo, LlmRawOption, PersonaOption, TermsResponse, DocumentInfoType } from '../../models/types';
import type { IAppInfoService } from '../../services/IAppInfoService';
import { useAuth } from '../auth/AuthContext';

type Props = {
    service: IAppInfoService;
};

enum AuthenticationErrorDetail {
    AccessBlocked = 'User is blocked',
}

export enum AuthenticationState {
    Authenticated = 'Authenticated',
    UnableToAuthenticate = 'UnableToAuthenticate',
    MissingRole = 'MissingRole',
    AccessBlocked = 'AccessBlocked',
    UnknownError = 'UnknownError',
}
interface IAppInfoContext {
    getTerms: () => Promise<TermsResponse>;
    getPersonaVersionReleaseNotes: (persona: string, version: string) => Promise<string>;
    authenticationState?: AuthenticationState;
    llms?: LlmRawOption[];
    personas?: PersonaOption[];
    access?: AccessInfo;
    documents?: DocumentInfoType;
}

const defaultAppInfoContext: IAppInfoContext = {
    authenticationState: undefined,
    llms: [],
    personas: [],
    access: {
        personas: {}
    },
    getTerms: function (): Promise<TermsResponse> {
        throw new Error('Function not implemented.');
    },
    getPersonaVersionReleaseNotes: function (): Promise<string> {
        throw new Error('Function not implemented.');
    }
};

const AppInfoContext = createContext<IAppInfoContext>(defaultAppInfoContext);

const AppInfoProvider: React.FC<PropsWithChildren<Props>> = ({ service, children }) => {
    const { isLoggingIn, isStartingUp, isLoggingOut } = useAuth();
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>();

    const [{ llm, personas, access, documents }, setInfoResponse] = useState<Partial<InfoResponse>>({});

    const didInitialize = useRef(false);
    useEffect(() => {
        if (didInitialize.current) return;
        if (isLoggingIn || isStartingUp || isLoggingOut) return setAuthenticationState(undefined);

        (async () => {
            didInitialize.current = true;
            try {
                const result = await service.getInfo();
                setAuthenticationState(AuthenticationState.Authenticated);
                setInfoResponse(result);
            } catch (error: any) {
                if (error.status) {
                    switch (error.status) {
                        case 401: {
                            // Unable to authenticate user
                            setAuthenticationState(AuthenticationState.UnableToAuthenticate);
                            return;
                        }
                        case 403: {
                            // 403   - Access denied (in blocked group)
                            if (error.data.detail === AuthenticationErrorDetail.AccessBlocked) setAuthenticationState(AuthenticationState.AccessBlocked);
                            // 403.1 - Missing role (not in any group)
                            else setAuthenticationState(AuthenticationState.MissingRole);
                            // setAuthenticationState(AuthenticationState.AccessBlocked);
                            return;
                        }
                    }
                }

                setAuthenticationState(AuthenticationState.UnknownError);
            }
        })();
    }, [isLoggingIn, isLoggingOut, isStartingUp, service]);

    return <AppInfoContext.Provider value={{ authenticationState, llms: llm, personas, access, documents, getTerms: service.getTerms, getPersonaVersionReleaseNotes: service.getPersonaVersionReleaseNotes }}>
        {children}
    </AppInfoContext.Provider>;
};

export const useAppInfo = () => useContext(AppInfoContext);

export default AppInfoProvider;
import DocumentUpdateModal from '../../../../../../../../elements/document-update-modal/DocumentUpdateModal';
import ChipList from '../../../../../../../chip-list/ChipList';
import Description from '../../../../../../../description/Description';
import ListItems from '../../../../../../../list-items/ListItems';
import StatusHeader from '../../../../../../../status-header/StatusHeader';
import DocumentListActions from '../document-list-actions/DocumentListActions';
import useLabels from '../../../../../../../../hooks/useLabels';
import useDocumentEvents from '../../../../../../../../elements/hooks/useDocumentEvents';
import Button, { ButtonThemes } from '../../../../../../../button/Button';
import Chip, { Version } from '../../../../../../../chip/Chip';
import EventHandler from '../../../../../../../../services/EventHandler';
import useStorage from '../../../../../../../../hooks/useStorage';
import { useState, useCallback, useMemo, FC, useEffect, useRef } from 'react';
import { TestIds } from '../../../../../../../../mocks/ids';
import { DocumentRow, DocumentsResponse, DocumentUpdateRequest } from '../../../../../../../../models/ChatTypes';
import { readableFileSize } from '../../../../../../../../helpers/fileHelpers';
import { DocumentUploadStatus, getFinalDocumentStatus, getStatusLabelType } from '../../../../../../../../helpers/statusHelpers';
import { ErrorStackType } from '../../../../../../../../contexts/error-handler/ErrorContext';
import { formatDate } from '../../../../../../../../helpers/dateHelpers';
import { AppRoute } from '../../../../../../../../router/Routing';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChat } from '../../../../../../../../contexts/chat/ChatContext';
import { NEW_PROMPT_PERSISTANCE_KEY } from '../../../../../../../../constants/consts';
import styles from './DocumentList.module.scss';

export type Props = {
    onRefresh: (documentIds?: string[]) => Promise<void>;
    onDelete: () => void;

    documents: DocumentRow[];
    setData: (value: React.SetStateAction<DocumentRow[]>) => void;
    setIsLoading: (state: boolean) => void;

    chatId: string;
    errorId: string;
    registerError: (error: ErrorStackType) => void,
    removeError: (errorId: string) => void,
    removePersistentDocsByIds: (ids: string[]) => void;
};

const DocumentList: FC<Props> = ({ chatId, documents, removePersistentDocsByIds, registerError, removeError, errorId, onDelete, onRefresh, setIsLoading, setData }) => {
    const labels = useLabels();
    const navigate = useNavigate();
    const { events } = useDocumentEvents();
    const { pathname, hash } = useLocation();
    const { updateDocumentMetadata, deleteDocuments, downloadDocuments, copyDocuments, persistent: { setInput } } = useChat();
    const { remove: removeStoredDocStatus } = useDocumentUploadStatusStore();

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<{ name: string; description: string; fileId: string; }>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loadingItemIds, setLoadingItemIds] = useState<string[]>();


    useEffect(() => { removeError(errorId); }, [errorId, removeError]);

    useEffect(() => {
        const hasInvalidStatus = documents.some((doc) => getFinalDocumentStatus(doc, events) === DocumentUploadStatus.Error);
        if (hasInvalidStatus) uploadStatusEvent.invoke(DocumentUploadStatus.Error);
        else uploadStatusEvent.invoke(DocumentUploadStatus.Ready);
    }, [documents, events]);


    const handleErrorRegistering = useCallback((headline: string, description: string, details: any) => registerError({ [errorId]: { type: 'notification', headline, details, description } }), [errorId, registerError]);

    const handleSelect = useCallback((id: string) => setSelectedIds(s => {
        if (s.indexOf(id) > -1) return s.filter(x => x !== id);
        return [...s, id];
    }), []);

    const handleEdit = useCallback((item: { name: string, description: string, fileId: string; }) => {
        setSelectedDocument(item);
        setErrorMessage(undefined);
        setModalOpen(true);
    }, []);

    const items = useMemo(() => documents.map((document) => {
        const { fileName, description, status, size, extension, documentId, created } = document || {};

        const calculatedStatus = getFinalDocumentStatus(document, events);
        const statusLabelMap = () => {
            switch (calculatedStatus) {
                case DocumentUploadStatus.Error: return labels.docErrorState;
                case DocumentUploadStatus.Processing: return labels.docProcessingState;
                case DocumentUploadStatus.Ready: return labels.docReadyState;
                default: return 'N/A';
            }
        };

        return {
            id: documentId,
            name: fileName,
            description: description,
            status,
            size,
            extension,
            header: <StatusHeader headline={fileName} />,
            footer: !description ? <Button className={styles['description-cta']} theme={ButtonThemes.textPrimary} label={labels.addDescription} onClick={() => handleEdit({ name: fileName, description, fileId: documentId })} /> : <Description description={description} labels={labels} />,
            children: <div className={styles['chip-wrapper']}>
                <Chip className={styles.progress} label={statusLabelMap()} theme={getStatusLabelType(calculatedStatus)} version={Version.minified} />
                <span className={styles.separator}>|</span>
                <ChipList chips={[
                    { label: readableFileSize(size) },
                    { label: extension },
                    { label: formatDate?.(created, 'en', true) }
                ]} />
            </div>,
            onItemSelect: handleSelect
        };
    }), [documents, events, handleEdit, handleSelect, labels]);


    const handleSelectAll = useCallback(() => setSelectedIds(items.map(item => item.id)), [items]);
    const handleDeselectAll = useCallback(() => setSelectedIds([]), []);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDocument(undefined);
        setErrorMessage(undefined);
    };


    const handleSubmit = useCallback(async (fileId: string, name: string, description: string) => {
        removeError(errorId);
        const metadata: DocumentUpdateRequest = { display: { name, description } };
        setLoadingItemIds(s => [...(s ?? []), fileId]);

        try {
            const response = await updateDocumentMetadata(fileId, metadata);
            if (!response) return;

            setData(prev => {
                const targetIndex = prev.findIndex((row => row.documentId === fileId));
                if (targetIndex === -1) {
                    console.error('Document not found in the list');
                    return prev;
                };

                prev.splice(targetIndex, 1, { ...prev[targetIndex], ...metadata, ...metadata?.display, fileName: metadata?.display?.name || '-' });

                return [...prev];
            });
            handleCloseModal();
        }
        catch (error) {
            handleErrorRegistering(labels.updateErrorHeadline, labels.updateErrorMessage, error);
        }
        finally {
            setLoadingItemIds(undefined);
        }
    }, [removeError, errorId, updateDocumentMetadata, setData, handleErrorRegistering, labels]);

    const handleDownload = useCallback(async () => {
        removeError(errorId);
        setLoadingItemIds(selectedIds);
        try {
            const success = await downloadDocuments(selectedIds);
            if (!success) registerError({ [errorId]: { type: 'notification', headline: labels.downloadErrorHeadline, description: labels.downloadErrorMessage } });
        }
        catch (error) {
            handleErrorRegistering(labels.downloadErrorHeadline, labels.downloadErrorMessage, error);
        }
        finally {
            setLoadingItemIds(undefined);
        }
    }, [removeError, errorId, selectedIds, downloadDocuments, registerError, labels, handleErrorRegistering]);

    const handleDelete = useCallback(async () => {
        removeError(errorId);
        setLoadingItemIds(selectedIds);
        try {
            const { delete_document_ids, errors } = await deleteDocuments(selectedIds, chatId);
            const hasDeletedDocs = delete_document_ids.length > 0;

            if (errors && Object.keys(errors).length) {
                const errorMessages = Object.values(errors).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.deleteErrorHeadline, description: `${labels.deleteErrorMessage} ${errorMessages}`, details: errors } });
            }

            if (hasDeletedDocs) {
                onDelete?.();
                setData(prev => {
                    const remainingDocuments = prev.filter(row => !delete_document_ids.includes(row.documentId));
                    if (chatId && !remainingDocuments.length) removeStoredDocStatus(chatId);
                    return remainingDocuments;
                });
            }

            // If no key defined it's same as NEW_PROMPT_PERSISTANCE_KEY
            if (hasDeletedDocs && chatId === undefined) {
                removePersistentDocsByIds(delete_document_ids);
                removeStoredDocStatus(NEW_PROMPT_PERSISTANCE_KEY);
            }

            setSelectedIds([]);
        }
        catch (error) {
            handleErrorRegistering(labels.deleteErrorHeadline, labels.deleteErrorMessage, error);
        }
        finally {
            setLoadingItemIds(undefined);
        }
    }, [removeError, errorId, selectedIds, deleteDocuments, chatId, registerError, labels.deleteErrorHeadline, labels.deleteErrorMessage, onDelete, setData, removeStoredDocStatus, removePersistentDocsByIds, handleErrorRegistering]);

    const handleCopy = useCallback(async (selectedIds: string[]) => {
        setIsLoading(true);

        try {
            removeError(errorId);
            const result: DocumentsResponse = await copyDocuments(selectedIds);

            if (result.errors && Object.keys(result.errors).length > 0) {
                const errorMessages = Object.values(result.errors).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.copyErrorHeadline, description: errorMessages } });
            }
            else {
                // Extract document IDs from result.documents
                const documents = Object.values(result.documents).map((doc) => doc.id);

                // Refresh documents list with new documents
                await onRefresh(documents);

                // Update document IDs in persistent state
                setInput(NEW_PROMPT_PERSISTANCE_KEY, { documents });

                // Navigate to the chat route if not already there
                if (pathname === AppRoute.chat) return;
                navigate({ pathname: AppRoute.chat, hash });
            }
        }
        catch (error: any) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            registerError({ [errorId]: { type: 'notification', headline: labels.copyErrorHeadline, description: errorMessage, details: error } });
        }
        finally {
            setIsLoading(false);
        }
    }, [setIsLoading, removeError, errorId, copyDocuments, registerError, labels.copyErrorHeadline, onRefresh, setInput, pathname, navigate, hash]);

    if (!items.length) return null;

    return (
        <div data-testid={TestIds.documentList} className={styles['document-list']}>
            <DocumentListActions
                data-testid={TestIds.documentListActions}
                items={items}
                selectedIds={selectedIds}
                onCopy={handleCopy}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                onEdit={handleEdit}
                onRefresh={onRefresh}
                onDownload={handleDownload}
                onDelete={handleDelete}
            />

            <ListItems
                data-testid={TestIds.documentListItems}
                childrenClassName={styles.children}
                headerClassName={styles.header}
                itemsSelected={selectedIds}
                items={items}
                loadingItemIds={loadingItemIds}
            />

            {selectedDocument && (
                <DocumentUpdateModal
                    errorId={errorId}
                    registerError={handleErrorRegistering}
                    removeError={removeError}
                    initialName={selectedDocument.name}
                    initialDescription={selectedDocument.description}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSubmit={(name, description) => handleSubmit(selectedDocument.fileId, name, description)}
                    errorMessage={errorMessage}
                />
            )}
        </div>
    );
};

export default DocumentList;

const uploadStatusEvent = new EventHandler<DocumentUploadStatus>();

/**
 * 
 * @param chatId 
 * It is important, because we need to store the upload status for each chat, 
 * in order to prevent unnecessary document load just so we could determine the 
 * upload status of an icon. (Without this, icon is 'black' until the side panel is clicked & document are loaded)
 */
const DOCUMENT_UPLOAD_STATUS_KEY = `document-upload-status`;

export const useDocumentUploadStatusStore = (chatId: string = '') => {
    const { get, remove, set } = useStorage(DOCUMENT_UPLOAD_STATUS_KEY, 'local', 'string');

    return {
        get: () => get(chatId),
        set: (status: DocumentUploadStatus) => set(chatId, status),
        remove: (chatId: string) => remove(chatId)
    };
};

export const useUploadStatus = (chatId: string) => {
    const charIdRef = useRef<string>();
    const [uploadStatus, setUploadStatus] = useState<DocumentUploadStatus>();
    const { get, set } = useDocumentUploadStatusStore(chatId);

    useEffect(() => {
        if (chatId === charIdRef?.current) return;
        charIdRef.current = chatId;
        const status = get();
        if (!status) return;
        setUploadStatus(status);
    }, [chatId, get, uploadStatus]);


    const handler = useCallback((status: DocumentUploadStatus) => {
        setUploadStatus(status);
        set(status);
    }, [set]);

    useEffect(() => {
        uploadStatusEvent.on(handler);
        return () => { uploadStatusEvent.off(handler); };
    }, [handler]);

    return {
        uploadStatus
    };
};
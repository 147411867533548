import { Color } from "../../constants/color";
import { BaseUIType } from "../../models/UITypes";
import { Icons } from "./icons/material";
import * as svgIcons from './icons/svg';

export type IconComponent = {
    Base: React.FC<IconProps>;
    Async: React.FC<AsyncIconProps>;
    Svg: React.FC<SvgIconProps>;
};


export enum IconTheme {
    inherit = 'inherit',
    light = 'light',
    dark = 'dark',
    accent = 'accent',

    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}
export enum IconStyles {
    filled = 'material-icons',
    outlined = 'material-icons-outlined'
}

type IconSize = 96 | 54 | 24 | 20 | 18 | 16;
type BorderSize = 0 | 1 | 2;


// Base
export type IconProps = BaseUIType & {
    title: string;
    size?: IconSize;
    className?: string;

    iconName: Icons;
    isLoading?: boolean;
    /** 
     * Usually icon is considered clickable when onClick handler is provided. 
     * However in some cases icons can be wrapped in a parent wrapper that handles clicking.
     * Thus an option to indicate override the default behavior is provided.
     * */
    isClickable?: boolean;
    isDisabled?: boolean;
    iconStyle?: IconStyles;
    theme?: IconTheme;
    dataAttributes?: Record<string, string>;
    onClick?: () => void;
};

// Async
export type AsyncIconProps = Omit<IconProps, 'onClick'> & {
    onAsyncClick: () => Promise<void>;
    flashFeedbackIcon?: boolean;
};

// Svg 
export type SvgIcons = keyof typeof svgIcons;

// export type SVGIconType = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string; }>;

export type SvgIconProps = Pick<IconProps, 'title' | 'className' | 'onClick' | 'size'> & BaseUIType & {
    iconName: SvgIcons;
    fill?: Color;
    borderSize?: BorderSize;
    borderColor?: Color;
    style?: React.CSSProperties;
};

import React from 'react';
import Chip from '../chip/Chip';
import { DfMenuItem } from '@danfoss/mosaic-react';
import { getClassNames } from '../../helpers/classHelpers';
import { wrapInOptionalLink } from '../../helpers/linkHelpers';
import { LinkProps } from 'react-router-dom';
import style from './List.module.scss';

export type ListProps = {
    listItems: ListItem[];
    className?: string;
};

export type ListItem = {
    title: string;
    url?: string;
    linkProps?: Omit<LinkProps, 'to'>;
    id: string;
    onClick?: () => void;
    onHoverIconClick?: () => void;
    hoverIcon?: React.ReactElement;
    icon?: React.ReactElement;
    className?: string;
};

const List: React.FC<ListProps> = ({ listItems, className }) => {
    return (
        <ul className={getClassNames([className, style.list])}>
            {listItems.map((item) => {
                const { title, url, hoverIcon, className, linkProps, id, icon, onClick, onHoverIconClick } = item || {};
                let content = <DfMenuItem className={getClassNames([className, style['list-item']])} onClick={onClick}>
                    {icon && <div className={style.icon}>{icon}</div>}
                    <span>{title}</span>
                    {hoverIcon && <Chip onClick={onHoverIconClick} className={style.chip} icon={hoverIcon} />}
                </DfMenuItem>;

                content = wrapInOptionalLink(content, url, linkProps);

                return <li onClick={onClick} key={id}>{content}</li>;
            })}
        </ul>
    );
};

export default List;
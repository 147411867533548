import React, { useCallback, useLayoutEffect, useRef } from 'react';
import useScrollLock from '../../hooks/useScrollLock';
import { ListItem } from '../../ui/list/List';
import { getClassNames } from '../../helpers/classHelpers';
import { DateGroup } from '../../helpers/dateHelpers';
import style from './Sidebar.module.scss';

type Props = {
    id?: string;
    className?: string;
    direction?: 'left' | 'right';
    isOpen?: boolean;
    scrollChildren?: React.ReactNode;
    headerChildren?: React.ReactNode;
    footerChildren?: React.ReactNode;

    onCloseSidebar?: () => void;
};

export type EnrichedListItem = ListItem & {
    date: string;
    dateGroup: DateGroup;
};

const Sidebar: React.FC<Props> = ({ id, className, direction = 'left', isOpen, scrollChildren, headerChildren, footerChildren, onCloseSidebar }) => {
    useScrollLock(isOpen);

    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollPositionRef = useRef(0);
    const openRef = useRef<boolean>();
    const onScroll = useCallback(() => scrollPositionRef.current = scrollRef.current?.scrollTop ?? 0, []);

    useLayoutEffect(() => {
        // TODO @MU: Solving scroll bar position reset. This could be solved possibly with CSS by not hiding the content.
        const $scroll = scrollRef.current;
        if (!$scroll)
            return;

        if (openRef.current === isOpen)
            return;

        openRef.current = isOpen;

        if (isOpen) {
            // Delay need for the content to render before setting the scroll position.
            setTimeout(() => {
                $scroll.scrollTop = scrollPositionRef.current;
            }, 1);
        }

    }, [isOpen]);

    return (<>
        <aside id={id} className={getClassNames([className, style[direction], style.sidebar, isOpen && '_sidebar-open'])}>
            {headerChildren}

            <div ref={scrollRef} className={style.scroll} onScroll={onScroll}>
                {scrollChildren}
            </div>
        </aside>
        <div onClick={onCloseSidebar} className={style.backdrop}></div>
        {footerChildren}
    </>);
};

export default Sidebar;
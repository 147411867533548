import useLabels from '../../../../hooks/useLabels';
//import useDcsEmbed from './actions/embed/useDcsEmbed';
import { Icon } from '../../../icon/Icon';
import { useCallback } from 'react';
import { RightSidebarProps } from '../../../../contexts/layout/LayoutContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DynamicRouteProperties } from '../../../../router/Routing';
import { processHashPaths } from '../../../../helpers/hashRouting';
import { Icons } from '../../../icon/icons/material';
//import styles from './useActions.module.scss';

import { Data } from './actions/data/Data';
import { Documents } from './actions/documents/Documents';
import { Pdf } from './actions/pdf/Pdf';
import { Trace } from './actions/trace/Trace';
import { isEnumValue } from '../../../../helpers/enumHelper';
import { sidePanelErrors } from '../../../../data/labels';
import { TestIds } from '../../../../mocks/ids';


// These Enum values are used in ChatView.module.scss as class names
export enum ObjectTypes {
    pdf = "pdf",
    trace = "trace",
    data = "data",
    documents = "documents",

    // Not used currently
    // txvEmbed = "txv-embed",
    // exvEmbed = "exv-embed",

    // View depth limit exceeded
    error = "error",
}

export enum ActionsErrorExceptions {
    viewDepthLimitExceeded = "view-depth-limit-exceeded"
}

export const createActionsErrorException = (exception: ActionsErrorExceptions) => {
    return `${ObjectTypes.error}-${exception}`;
};

export const usePathRouting = () => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const { chatId } = useParams() as DynamicRouteProperties;

    const invokeNavigationToSidePanel = (newHash: string, invokeWithoutChatId = false) => {
        if (!invokeWithoutChatId && !chatId) return;
        const returnHash = processHashPaths(hash, newHash);
        navigate(returnHash);
    };

    return { invokePathNavigateToSidePanel: invokeNavigationToSidePanel };
};

export const useChatSidePanelContent = () => {
    //const { exvEmbed, txvEmbed } = useDcsEmbed();
    const labels = useLabels();

    const factory = useCallback((chatId: string, objectType: string, objectId?: string): RightSidebarProps => {
        let type = ObjectTypes.error;
        let id = objectId!;
        if (isEnumValue(ObjectTypes, objectType)) {
            type = objectType as ObjectTypes;
        }
        if (!id) {
            id = "invalid id";
        }
        switch (type) {
            case ObjectTypes.data: {
                return {
                    header: <b></b>,
                    children: <Data objectId={objectId!} objectType={ObjectTypes.data} chatId={chatId} />,

                };
            }
            case ObjectTypes.documents: {
                return {
                    header: <b>{labels.uploadDocumentTitle}</b>,
                    children: <Documents chatId={chatId} />,

                };
            }
            case ObjectTypes.pdf: {

                let fileUrl = id;
                if (fileUrl) fileUrl = decodeURIComponent(fileUrl);
                return {
                    header: !fileUrl ? undefined : <Icon.Base title={labels.openInNewTabTitle} iconName={Icons.openInNewWindow} onClick={() => window.open(fileUrl, "_blank")} />,
                    children: <Pdf url={fileUrl} />,

                };
            }
            case ObjectTypes.trace: {
                return {
                    header: <b>{labels.chainViewerHeadline}</b>,
                    children: <Trace traceId={id} />,

                };
            }
            case ObjectTypes.error:
            default:
                {
                    const errorLabels = sidePanelErrors[id] ?? { headline: 'Error', description: '' };
                    return {
                        header: <b>{errorLabels.headline}</b>,
                        children: <p data-testid={TestIds.errorView}>{errorLabels.description}</p>,
                    };
                }
            // [ObjectTypes.txvEmbed]: (objectId: string) => {
            //     txvEmbed({ culture, labels, metadata: dataset, addRightSidebarContent, showRightSidebar });
            // },
            // [ObjectTypes.exvEmbed]: (objectId: string) => {
            //     exvEmbed({ culture, labels, metadata: dataset, addRightSidebarContent, showRightSidebar });
            // },            
        };
    }, [labels]);

    return factory;
};

export const createAssetActionLink = (url: string, type = ObjectTypes.pdf) => `${type.replace('.', '').trim()}-${url}`;

import React, { useState, useCallback, useMemo, Fragment, } from 'react';
import MessageActions from '../message-actions/MessageActions';
import useLabels, { useChatMessageErrorLabels } from '../../hooks/useLabels';
import LoadingIcon from '../../ui/loading-icon/LoadingIcon';

import ErrorBoundary from '../../error/ErrorBoundary';
import Alert from '../../ui/alert/Alert';
import Chip from '../../ui/chip/Chip';
import Divider from '../../ui/divider/Divider';
import Modal from '../../ui/modal/Modal';
import Tag from '../../ui/tag/Tag';
import Button, { ButtonThemes } from '../../ui/button/Button';
import MarkdownWrapper from '../../ui/markdown-wrapper/MarkdownWrapper';
import useMessageProps from '../../hooks/useMessageProps';
import AvatarIcon from '../../ui/avatar-icon/AvatarIcon';
import { Icon } from '../../ui/icon/Icon';
import { groupBy, sortWith } from '../../helpers/listsHelpers';
import { getClassNames } from '../../helpers/classHelpers';
import { useChat } from '../../contexts/chat/ChatContext';
import { Vote } from '../../models/types';
import { MessageDisplayProps } from '../chat-window/ChatWindow.types';
import { MediaSize, gptBorderColor, gptColor } from '../../constants/consts';
import { ChatMessageErrorCode } from '../../helpers/messageStreamHelper';
import { useMediaSize } from '../../hooks/useMediaSize';
import { Icons } from '../../ui/icon/icons/material';
import { SvgIcons } from '../../ui/icon/Icon.types';
import style from './Message.module.scss';


const Message: React.FC<MessageDisplayProps> = ({ chatId, message, feedback, className, llm, persona }) => {
    const { isGpt, isBeta, senderAvatar, senderName, senderExtraName } = useMessageProps(message.type, llm, persona);

    const generationStatus = message.generationStatus;
    const isStreaming = !!message.isStreaming;

    const parseAsMarkdown = isGpt;
    const showMessageActions = isGpt && !isStreaming;

    const color = isGpt ? gptColor : undefined;
    const borderColor = isGpt ? gptBorderColor : 'white';

    const labels = useLabels();

    const context = useMemo(() => message.context?.sources?.map(x => {
        const s = { ...x };
        s.source ||= labels.miscSources;
        return s;
    }) ?? [], [message.context, labels]);
    const enquiry = useMemo(() => message.enquiry, [message.enquiry]);

    const errorMessageDict = useChatMessageErrorLabels();

    const [voteState, setVoteState] = useState<Vote>(feedback?.vote || 0);
    const { messageLike, messageDislike, messageResetVote, messageComment, getMessageFeedback } = useChat();
    const [comment, setComment] = useState(feedback?.comment || '');
    const [loadedComment, setLoadedComment] = useState<string>();

    const onCommentChangeHandler = useCallback((value: string) => {
        setComment(value);
        setLoadedComment(undefined);
    }, []);

    const resetVote = useCallback(async () => {
        if (!chatId) return;
        try {
            await messageResetVote(chatId, message.id);
            setVoteState(0);
        } catch (e) { console.error(e); }
    }, [chatId, message.id, messageResetVote]);

    const onVoteHandler = useCallback(async (vote: Vote) => {
        if (!chatId) return;
        try {
            if (voteState === vote) resetVote();
            else {
                const handler = vote === 1 ? messageLike : messageDislike;
                await handler(chatId, message.id);
                setVoteState(vote);
            }
        } catch (err) {
            console.error(`Failed to vote (${vote === 1 ? 'like' : 'dislike'}): `, err);
        }
    }, [chatId, message.id, messageDislike, messageLike, resetVote, voteState]);

    const onLikeClickHandler = useCallback(async () => onVoteHandler(1), [onVoteHandler]);
    const onDislikeClickHandler = useCallback(async () => onVoteHandler(-1), [onVoteHandler]);

    const onLoadFeedbackHandler = useCallback(async () => {
        if (!chatId) return;

        const feedback = await getMessageFeedback(chatId, message.id);
        if (!feedback) return;

        const c = feedback.comment;
        if (c) {
            setComment(c);
            setLoadedComment(c);
        }
    }, [chatId, message.id, getMessageFeedback]);

    const onCommentSaveHandler = useCallback(async () => {
        if (!chatId) return;
        await messageComment(chatId, message.id, comment);
        setComment('');
    }, [chatId, comment, message.id, messageComment]);

    const renderMessageText = useMemo(() => {
        // If message is not a string, it's a ReactNode. Return it as is.
        if (message.error) return <Alert type='error' headline={message.error} />;
        // If message is a string, but we don't want to parse it as markdown, return with <br/> for new lines.
        else if (!parseAsMarkdown) return message.content.split('\n').map((line, i) => <Fragment key={i}>{i !== 0 && <br />}{line}</Fragment>);
        // Else parse it as markdown.
        else return <ErrorBoundary fallback={<Alert type='error' headline={labels.markdownRenderError} />}>
            <MarkdownWrapper isLoading={isStreaming} markdown={message.content} errorMessageDict={errorMessageDict} defaultError={ChatMessageErrorCode.Unknown} />
        </ErrorBoundary>;
    }, [errorMessageDict, message.content, message.error, isStreaming, parseAsMarkdown, labels]);

    const [selectedSourceUrl, setSelectedSourceUrl] = useState<string>();
    const [showEnquiry, setShowEnquiry] = useState<boolean>();
    const selectedSource = useMemo(() => {
        return context.reduce<undefined | { source: string; sources: { url: string; snippet: string; }[]; }>((acc, s) => {
            if (s.source !== selectedSourceUrl) return acc;
            if (!acc) return {
                source: s.source,
                sources: [{ url: s.url, snippet: s.snippet }],
            };
            else acc.sources.push({ url: s.url, snippet: s.snippet });

            return acc;
        }, undefined);

    }, [context, selectedSourceUrl]);

    const renderSenderAvatar = useMemo(() => {
        if (['https://', 'http://', 'data:image', 'blob:'].some(start => senderAvatar.startsWith(start))) {
            return <AvatarIcon imageUrl={senderAvatar} color={color} borderColor={borderColor} />;
        }
        return <Icon.Svg title='' iconName={senderAvatar as SvgIcons} fill={'red'} borderColor={'red'} borderSize={1} style={{ padding: '2px', width: 26, height: 26 }} />;
    }, [senderAvatar, color, borderColor]);

    const mobileHeadline = useMemo(() => <h3 className={getClassNames([style.headline])}>
        <span className={style['headline-base']}>
            <div className={style.avatar}>
                {renderSenderAvatar}
            </div>
            <div className={style.name}>
                {senderName}
            </div>
        </span>

        <div className={style['headline-extras']}>
            {senderExtraName && <span className={style.extras}>{senderExtraName}</span>}
            {isBeta && <Tag label={labels.beta} hideBorder className={style.beta} />}

            {!!enquiry?.resultReason &&
                <Icon.Base title={labels.enquiryAnalysisTitle} className={getClassNames(['df-icon', style['enquiry-analysis']])} iconName={Icons.info} onClick={() => setShowEnquiry(true)} />
            }
        </div>
    </h3>, [renderSenderAvatar, senderName, senderExtraName, isBeta, labels.beta, labels.enquiryAnalysisTitle, enquiry?.resultReason]);


    const desktopHeadline = useMemo(() => <h3 className={style.headline}>
        <span>
            {senderName}
        </span>
        {senderExtraName && <span className={style.extras}>{senderExtraName}</span>}
        {isBeta && <Tag label={labels.beta} hideBorder className={style.beta} />}

        {!!enquiry?.resultReason &&
            <Icon.Base title={labels.enquiryAnalysisTitle} className={getClassNames(['df-icon', style['enquiry-analysis']])} iconName={Icons.info} onClick={() => setShowEnquiry(true)} />
        }
    </h3>, [senderName, senderExtraName, isBeta, labels.beta, enquiry?.resultReason, labels.enquiryAnalysisTitle]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);

    return (
        <div className={getClassNames([className, style.message, isMobile && style.mobile])}>
            {!isMobile && <div className={style.avatar}>
                {renderSenderAvatar}
            </div>}

            <div className={style.content}>
                {isMobile ? mobileHeadline : desktopHeadline}
                <div className={style['message-text']}>
                    {renderMessageText}
                    {isStreaming && <>
                        <LoadingIcon />
                        <span className={style['generation-status']}>{generationStatus}</span>
                    </>}
                </div>
                {
                    context.length > 0 && <>
                        <Divider />
                        <div className={style['source-container']}>
                            <b>{labels.sources}:</b>
                            <div>
                                {
                                    sortWith(groupBy(context, 'source'), x => x.group).map(({ group, items }, i) => {
                                        if (!items.length) return null;
                                        return <Chip
                                            label={group}
                                            onClick={() => setSelectedSourceUrl(group)}
                                            isActive={selectedSourceUrl === items[0].url}
                                            className={style.chip}
                                            key={i}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </>
                }

                <Modal
                    size='large'
                    isOpen={!!selectedSource}
                    onClose={() => setSelectedSourceUrl(undefined)}
                    headline={labels.sources}
                    closeOnEscape
                    body={<div>
                        {selectedSource?.sources.map((x, i) => {
                            return <div className={style.snippet} key={x.url}>
                                <Divider />
                                <ViewMore text={x.snippet} url={x.url} />
                            </div>;
                        })}
                    </div>}
                    footer={<>
                        <Button label={labels.cancel} onClick={() => setSelectedSourceUrl(undefined)} theme={ButtonThemes.textPrimary} />
                    </>}
                />

                <Modal
                    size='large'
                    isOpen={!!showEnquiry}
                    onClose={() => setShowEnquiry(false)}
                    headline={labels.enquiryHeadline}
                    closeOnEscape
                    body={<div>
                        <>
                            {enquiry?.resultReason}
                            {enquiry?.questions?.map((q, i) => <div key={i}>
                                <Divider />
                                <b>{labels.enquiryQuestion}:</b> {q.query || "-"}<br />
                                <b>{labels.enquiryCategory}:</b> {q.category || "-"}<br />
                                <b>{labels.enquiryKeywords}:</b> {q.keywords?.join(", ") || "-"}<br />
                            </div>
                            )}
                        </>
                    </div>}
                    footer={<>
                        <Button label={labels.cancel} onClick={() => setShowEnquiry(undefined)} theme={ButtonThemes.textPrimary} />
                    </>}
                />
                {showMessageActions && <MessageActions
                    isLiked={voteState === 1}
                    onLikeClick={onLikeClickHandler}
                    isDisliked={voteState === -1}
                    onDislikeClick={onDislikeClickHandler}
                    onLoadFeedback={onLoadFeedbackHandler}
                    loadedComment={loadedComment}
                    comment={comment}
                    onCommentChange={onCommentChangeHandler}
                    onCommentSave={onCommentSaveHandler}
                    message={message}
                />}
            </div>
        </div>
    );
};

function ViewMore({ text, url, }: { text: string; url: string; }) {
    const labels = useLabels();
    const maxTextLength = 300;

    const [isExpanded, setIsExpanded] = useState(false);
    const isExceedingLimit = useMemo(() => {
        return text.length > maxTextLength;
    }, [text.length]);

    return <div className={style['view-more']}>
        <div style={{ 'whiteSpace': 'pre-wrap' }}>{isExpanded || !isExceedingLimit ? text : text.substring(0, maxTextLength) + '...'}</div>
        <div className={style['snippet-ctas']}>
            {isExceedingLimit && <Button isSmall onClick={() => setIsExpanded(p => !p)} label={isExpanded ? labels.viewLess : labels.viewMore} theme={ButtonThemes.textPrimary} />}
            <a href={url} target='_blank' rel="noreferrer" ><Button isSmall label={labels.goToSource} theme={ButtonThemes.secondary} /></a>
        </div>
    </div>;
}

export default Message;
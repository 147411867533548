import PageLayout from '../../layouts/page-layout/PageLayout';
import Sidebar, { type EnrichedListItem } from '../../elements/sidebar/Sidebar';
import NewChatWindow from '../../elements/new-chat-window/NewChatWindow';
import ChatWindow from '../../elements/chat-window/ChatWindow';
import useStateRef from '../../hooks/useStateRef';
import SidebarHeader from '../../elements/sidebar-header/SidebarHeader';
import Button, { ButtonProps, ButtonThemes } from '../../ui/button/Button';
import SidebarChildren from '../../elements/sidebar-children/SidebarChildren';
import useDisclaimer from '../../hooks/useDisclaimer';
import useLabels from '../../hooks/useLabels';
import useLoadAllChats from '../../elements/hooks/useLoadAllChats';
import { Icon } from '../../ui/icon/Icon';
import { IconTheme } from '../../ui/icon/Icon.types';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { findDateGroup } from '../../helpers/dateHelpers';
import { AppRoute, getChatItemRoute } from '../../router/Routing';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../../contexts/chat/ChatContext';
import { getPersonaIcon } from '../../helpers/avatarHelpers';
import { getClassNames } from '../../helpers/classHelpers';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize, sidePanelElementId } from '../../constants/consts';
import { SidebarHistoryBody, SidebarHistoryFooter, useSidebarDeletionModal } from '../../elements/sidebar/sidebar-history/SidebarHistory';
import { LayoutProvider, useLayout } from '../../contexts/layout/LayoutContext';
import { TestIds } from '../../mocks/ids';
import { Icons } from '../../ui/icon/icons/material';
import { extractHashProps, getHashRoutePaths } from '../../helpers/hashRouting';
import { useChatSidePanelContent } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { useDocumentUploadStatusStore } from '../../ui/markdown-wrapper/hooks/useActions/actions/documents/components/document-list/DocumentList';
import style from './ChatView.module.scss';

type Props = {
    newPrompt?: boolean;
    newPromptOrigin?: string;
};

export const PERSISTENT_INPUT_KEY = 'input-history';

export type ChatProps = {
    newPromptOrigin?: string;
    currentChat?: SessionResponse;
    chatId: string;
    onOpenSidebar?: () => void;
    onReloadChats?: (force?: boolean) => Promise<void>;
};

const ChatView: FC<Props> = memo((props) => {
    return (
        <LayoutProvider>
            <Content {...props} />
        </LayoutProvider>
    );
});

export default ChatView;

type HashRouteStackItem = { type: string, id: string; chatId: string; };
type HashRouteStack = HashRouteStackItem[];
const useHashRouteStack = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { chatId } = useParams();
    const [stack, setStack] = useState<HashRouteStack>([]);

    const hashes = useMemo(() => getHashRoutePaths(hash), [hash]);
    useEffect(() => {
        const newStack: HashRouteStack = [];
        for (const hash of hashes) {
            const { objectType, objectId } = extractHashProps(hash);
            newStack.push({ type: objectType, id: objectId, chatId: chatId! });
        }

        setStack(s => {
            if (JSON.stringify(newStack) === JSON.stringify(s))
                return s;
            return newStack;

        });
    }, [chatId, hashes]);

    const up = useCallback(() => {
        const newHashes = [...hashes];
        newHashes.pop();
        navigate({ hash: newHashes.join("/") });
    }, [hashes, navigate]);

    const clear = useCallback(() => {
        navigate({ hash: '' });
    }, [navigate]);

    return { stack, up, clear };
};


const Content: FC<Props> = memo(({ newPrompt, newPromptOrigin }) => {
    const navigate = useNavigate();
    const { chatId } = useParams();
    const { getAllChats, deleteChat, deleteAllChats: deleteChats } = useChat();

    // TODO: MU remove useLayout and useLayoutContext
    const { isLeftSidebarVisible, isRightSidebarVisible, showLeftSidebar, hideLeftSidebar, hideRightSidebar, showRightSidebar } = useLayout();

    const [chats, setChats, chatsRef] = useStateRef<SessionResponse[]>();
    useLoadAllChats({ setChats });

    const reloadChats = useCallback(async (force?: boolean) => {
        // only reload if the chatId is not the same as the first chat in the list, to update order of chats based on recent activity
        if (!force && chatId === chatsRef.current?.[0]?.session_id) return Promise.resolve<void>(undefined);
        const updatedChats = await getAllChats();
        setChats(updatedChats);

    }, [chatId, getAllChats, setChats, chatsRef]);

    const { remove } = useDocumentUploadStatusStore();
    const handleDeleteChat = useCallback(async (id: string) => {
        const isCurrent = chatId === id;

        try {
            await deleteChat(id);
            remove(id);
        }
        catch (e) {
            console.error('Failed to delete chat.', e);
        }
        finally {
            await reloadChats(true);
            if (isCurrent) navigate(AppRoute.chat);
        }
    }, [chatId, deleteChat, navigate, reloadChats, remove]);

    const handleDeleteAllChats = useCallback(async () => {
        if (!chats?.length) return Promise.reject<void[]>() as any;
        try {
            await deleteChats();
            // Remove all status from store  
            chatsRef.current?.forEach(chat => remove(chat.session_id));

        } catch (e) {
            console.error('Failed to delete all chats.', e);
        } finally {
            const updatedChats = await getAllChats();
            setChats(updatedChats);
            hideLeftSidebar();
            navigate(AppRoute.chat);
        }
    }, [chats?.length, deleteChats, chatsRef, remove, getAllChats, setChats, hideLeftSidebar, navigate]);

    const history = useMemo<EnrichedListItem[]>(() => {
        return chats?.map((chat) => {
            return {
                id: chat.session_id,
                title: chat.subject || 'Missing subject',
                date: chat.created,
                icon: <Icon.Svg title='' size={20} className={style.avatar} borderSize={0} fill='black' iconName={getPersonaIcon(chat.persona)} />,
                dateGroup: findDateGroup(chat.updated),
                url: getChatItemRoute(chat.session_id),
            };
        }) ?? [];
    }, [chats]);

    const currentChat = useMemo(() => chats?.find(chat => chat.session_id === chatId), [chatId, chats]);

    const { deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen } = useSidebarDeletionModal();

    const props = useMemo(() => ({
        listItems: history,
        deleteModalChatId,
        isDeleteAllModalOpen,
        setDeleteModalChatId,
        setIsDeleteAllModalOpen,
        onDeleteChat: handleDeleteChat,
        onDeleteAllChats: handleDeleteAllChats,
        onCloseSidebar: hideLeftSidebar,
    }), [history, deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen, handleDeleteChat, handleDeleteAllChats, hideLeftSidebar]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);
    const isTablet = useMediaSize((ms) => ms <= MediaSize.lg);

    const onCreateNewChatHandler = useCallback(() => {
        isMobile && hideRightSidebar();
        hideLeftSidebar();
    }, [isMobile, hideLeftSidebar, hideRightSidebar]);

    const { stack: hashStack, up: hashStackUp, clear: hashStackClear } = useHashRouteStack();

    // Relevant to enforce certain CSS classes that are dependent on __isRightSidebarVisible__
    useEffect(() => {
        if (hashStack.length > 0) showRightSidebar();
        else hideRightSidebar();
    }, [hashStack.length, hideRightSidebar, isRightSidebarVisible, showRightSidebar]);

    const handleSideBarOpen = useMemo(() => {
        if (isTablet && isRightSidebarVisible) return hashStackClear;
        if (isMobile) return showLeftSidebar;
        else return undefined;
    }, [hashStackClear, isMobile, isRightSidebarVisible, isTablet, showLeftSidebar]);

    return <PageLayout
        testId={TestIds.chatViewContent}
        notification={{
            id: 'chatbot-interface-change',
            label: 'The Danfoss AI Chatbot interface is changing. Changes are expected before the end of November. To learn more',
            urlLabel: 'click here',
            url: 'https://danfoss.sharepoint.com/sites/AI/SitePages/Danfoss-AI-Chatbot.aspx',
            persist: 'session'
        }}
    >
        <div className={getClassNames([style['chat-view'], isLeftSidebarVisible && style['opened-left'], isRightSidebarVisible && style['opened-right']])}>
            <Sidebar
                isOpen={isLeftSidebarVisible}
                className={style['left-sidebar']}
                onCloseSidebar={hideLeftSidebar}
                headerChildren={<SidebarHeader>
                    <NewChatButton onClick={onCreateNewChatHandler} />
                </SidebarHeader>}
                scrollChildren={<SidebarHistoryBody {...props} />}
            />

            <div className={style.main}>
                <ActiveChatWindow
                    chatId={chatId!}
                    currentChat={currentChat}
                    onReloadChats={reloadChats}
                    onOpenSidebar={handleSideBarOpen}
                    newPrompt={newPrompt}
                    newPromptOrigin={newPromptOrigin}
                />
            </div>

            {hashStack.map((content, i) => <SidePanelView key={i} {...content} isCurrent={i === hashStack.length - 1} isRoot={i === 0} onClose={hashStackUp} />)}
        </div>

        <Disclaimer />
        <SidebarHistoryFooter {...props} />
    </PageLayout>;
});

const SidePanelView = memo(({ chatId, type, id, isCurrent, isRoot, onClose }: HashRouteStackItem & { isCurrent: boolean; isRoot: boolean; onClose: () => void; }) => {
    const factory = useChatSidePanelContent();
    const content = useMemo(() => factory(chatId, type, id), [factory, chatId, type, id]);
    return (
        <Sidebar
            id={sidePanelElementId}
            direction='right'
            isOpen={isCurrent}
            className={getClassNames([isCurrent && style['right-sidebar-visible'], style['right-sidebar'], style[type]])}
            onCloseSidebar={onClose}
            headerChildren={
                <SidebarHeader
                    iconName={isRoot ? Icons.clear : Icons.arrowBack}
                    onClose={onClose}
                >
                    {content.header}
                </SidebarHeader>
            }
            scrollChildren={<SidebarChildren className={style.children}>{content.children}</SidebarChildren>}
        />
    );
});

const ActiveChatWindow = memo((props: ChatProps & Props) => {
    if (props.newPrompt) return <NewChatWindow {...props} />;
    return <ChatWindow {...props} />;
});

const Disclaimer = memo(() => {
    const { renderDisclaimer } = useDisclaimer();
    return renderDisclaimer({ className: style.disclaimer });
});

export const NewChatButton = memo(({ onClick }: ButtonProps) => {
    const labels = useLabels();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const l = useMemo(() => {
        return {
            nc: labels.newChat,
        };
    }, [labels.newChat]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);

        if (pathname !== AppRoute.chat) navigate(AppRoute.chat);
    };

    return <Button
        className={style.cta}
        label={l.nc}
        isSmall={isMobile}
        theme={ButtonThemes.secondary}
        onClick={handleOnClick}
        icon={<Icon.Base title='' theme={IconTheme.inherit} iconName={Icons.add} />} // Title not desired since it's used in button
    />;
});
export enum TestIds {
    // ChatInputOption
    advancedOptionsModalIcon = 'advanced-options-modal',
    advancedOptionsModal = 'advanced-options-modal',
    dfSelectOption = 'df-select-option',
    dfSelect = 'df-select',

    // Alert
    alertDetailsComponent = 'alert-details-component',
    alertDetailsButton = 'alert-details-button',

    // ChatInput
    chatInput = 'chat-input',
    chatInputContainer = 'chat-input-container',
    sendButton = 'send-button',
    uploadDocButton = "upload-doc-button",

    // CharWindow | NewChatWindow
    chatWindow = 'chat-window',

    // ChatWindowHeader
    inputOverlay = 'input-overlay',

    // LoadingIcon | ChatWindowHeader | 
    loadingIcon = 'loading-icon',

    // Accordion 
    accordionBase = 'accordion',

    // ChatView
    chatViewContent = 'chat-view-content',

    //
    pdfContainer = 'pdf-container',
    pdfViewer = 'pdf-viewer',
    pdfViewerLoader = 'pdf-viewer-loader',
    pdfError = 'pdf-error',

    // Trace
    traceContainer = 'trace-container',
    traceView = 'trace-view',

    // Document 
    documentView = 'document-view',
    documentList = 'document-list',
    documentListActions = 'document-list-actions',
    documentListItems = 'document-list-items',

    // Error 
    errorView = 'error-view',

    // useActions
    dataSidePanel = 'data-side-panel',

    //fileinput
    fileInput = 'file-upload-input',
    fileDropArea = 'file-drop-area',
    downloadButton = 'download-button',
    copyButton = 'copy-button',
    refreshButton = 'refresh-button',
    deleteButton = 'delete-button',
    editButton = 'edit-button'




}